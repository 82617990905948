import React, { useEffect } from "react";
import Header from "../../Layout/Header/Header";
import Footer from "../../Layout/Footer/Footer";
import Countdown from "react-countdown";
import "../About/ABT-Convention/Abtconvention.css";
import { useDispatch, useSelector } from "react-redux";
import "./home.css";

import { Link, useParams,useLocation } from "react-router-dom";
import ReactGA from 'react-ga';
import {
  fetchCmsPages3,
  fetchCmsPages,
  fetchCmsPages2,
  fetchSpeakerData,
} from "../../Redux/Slice";
import { Helmet, HelmetProvider } from "react-helmet-async";
// import $ from 'jquery';
// import 'owl.carousel/dist/assets/owl.carousel.css';
// import 'owl.carousel/dist/assets/owl.theme.default.css';
// import 'owl.carousel';

function Home() {
  const { slug } = useParams();
  const dispatch = useDispatch();
  const cmsStatus = useSelector((state) => state.cms.status);
  const page = useSelector((state) => state.cms.page);
  const speaker_Record = useSelector((state) => state.cms.speaker_Record.data);

  const location = useLocation();

  useEffect(() => {
    ReactGA.pageview(location.pathname + location.search);
  }, [location]);

  useEffect(() => {
    dispatch(fetchSpeakerData());
    if (slug) {
      console.log("Fetching CMS page with slug:", slug);
      dispatch(fetchCmsPages());
      dispatch(fetchSpeakerData());
      dispatch(fetchCmsPages2());
      dispatch(fetchCmsPages3(slug));
    }
  }, [dispatch, slug]);

  console.log("speaker_Record", speaker_Record);

  useEffect(() => {
    const tabButtons = document.querySelectorAll(".tab-btn");
    const tabsContent = document.querySelectorAll(".tab");

    tabButtons.forEach((button) => {
      button.addEventListener("click", () => {
        tabButtons.forEach((btn) => btn.classList.remove("active-btn"));
        tabsContent.forEach((tab) => tab.classList.remove("active-tab"));

        button.classList.add("active-btn");
        const target = document.querySelector(button.dataset.tab);
        target.classList.add("active-tab");
      });
    });
  }, []);

  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      return <span>Event has started!</span>;
    } else {
      return (
        <div className="countdown-wrapper text-center mt-4">
          <span className="number">
            {days} <br /> <span className="label">Days</span>
          </span>
          {/* <span className="label">Days</span> */}
          <br />
          <span className="midSpn">:</span>

          <span className="number">
            0{hours} <br /> <span className="label">Hours</span> <br />
          </span>
          <br />
          {/* <span className="label">Hours</span> */}

          <span className="midSpn">:</span>

          <span className="number">
            {minutes} <br />
            <span className="label">Minutes</span>
          </span>
          <br />
          {/* <span className="label">Minutes</span> */}

          <span className="midSpn">:</span>

          <span className="number">
            {seconds} <br /> <span className="label">Seconds</span>
          </span>
          <br />
        </div>
      );
    }
  };

  return (
    <div class="page-wrapper">
      {/* <Header/> */}
      <section class="banner-section">
        <div class="banner-carousel  owl-theme">
          {/* <div class="container"
           style={{ backgroundImage: "url(images/slider-logo.png)" }}>
          </div> */}
          <div
            class="slider"
            // style={{ backgroundImage: "url(images/main-slider/1.jpg)" }}
            style={{ backgroundImage: "url(images/slider bag.png)",}}
          >
            <div class="auto-container" >
              <div class="content-box text-center"  >
              <img src="images/logo.png" width="35%"/>
              {/* <img src="" alt="" /> */}
                <span class="title  clss-ttl " >
                  5th & 6th August 2024
                  </span>
                <h2 className="text-dark main-title" >
                  3rd HRANI Convention
                  
                  </h2>
                <ul class="info-list class-list" >
                  <li className="text-dark vanue">
                    <span class="icon fa fa-map-marker-alt"></span> India Expo
                    Centre & Mart, Greater Noida
                  </li>
<br />
                  <li className="text-dark vanue">Timing : 10:30 Am -05:00 Pm</li>
                </ul>
                <button class="" style={{zIndex:"99",position:'relative',top:'90px'}}>
                  <a href="https://admin.hraniconvention.com/static-visitor?org_id=668d11d87d72eb70ec482b18&exb_id=668d12e57d72eb70ec482c69&form_id=668e7fecfa3da7855d1f36b9&form=web" class="theme-btn btn-style-two">
                    <span class="btn-title">Click to Register</span>
                  </a>
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="coming-soon-section mt-5">
        <div class="auto-container">
          <div class="outer-box">
            <div class="time-counter text-center">
              {/* <div
                class="time-countdown clearfix"
                data-countdown="08/05/2024"
              ></div> */}
              <Countdown
                date={new Date("2024-08-05T00:00:00")}
                renderer={renderer}
              />
            </div>
          </div>
        </div>
      </section>

      <section class="about-section">
        <div class="anim-icons full-width">
          <span class="icon icon-circle-blue wow fadeIn"></span>
          <span class="icon icon-dots wow fadeInleft"></span>
          <span class="icon icon-circle-1 wow zoomIn"></span>
        </div>
        <div class="auto-container">
          <div class="row">
            <div class="content-column col-lg-6 col-md-12 col-sm-12">
              <div class="inner-column">
                <div class="sec-title">
                  <span class="title">About HRANI Convention</span>
                </div>
                <ul class="list-style-one">
                  <li>
                    The 3rd HRANI Convention, themed “उदयन् भारतम् | Rising
                    India – Shaping a New Era in Hospitality & Tourism,” will be
                    held on 5th & 6th August 2024 at the India Expo Centre &
                    Mart, Greater Noida. This event aims to be a dynamic forum
                    for professionals across the hospitality and tourism sectors
                    to explore and address the industry's most significant
                    challenges and opportunities.
                  {/* </li>
                  <li> */}
                    The convention will feature an array of engaging events,
                    including insightful keynote speeches, thought-provoking
                    panel discussions, workshops, and interactive sessions led
                    by industry veterans and thought leaders. Topics will cover
                    emerging hospitality trends, customer-centric strategies,
                    technological advancements, sustainable practices, and
                    operational excellence.
                  {/* </li>
                  <li> */}
                    Attendees will have the opportunity to network, exchange
                    ideas, and collaborate on innovative solutions to drive
                    growth and success in the industry. The HRANI Convention
                    promises to be an enriching experience, paving the way for a
                    brighter future in hospitality and tourism.
                  </li>
                </ul>
                <div class="row">
                            <div class="about-block col-lg-6 col-md-6 col-sm-12">
                                <div class="inner-box">
                                    <h4><span class="icon fa fa-map-marker-alt"></span> Where</h4>
                                    <div class="text">India Expo Centre & Mart, Greater Noida</div>
                                </div>
                            </div>
                            <div class="about-block col-lg-6 col-md-6 col-sm-12">
                                <div class="inner-box">
                                    <h4><span class="icon fa fa-clock"></span> when</h4>
                                    <div class="text">5th & 6th August 2024</div>
                                </div>
                            </div>
                        </div>
              </div>
            </div>
            <div class="image-column col-lg-6 col-md-12 col-sm-12">
              <div class="image-box">
                <figure class="image wow fadeIn">
                  <img src="images/resource/about-img-1.jpg" alt="" />
                </figure>
              </div>
            </div>
          </div>
          
        </div>
      </section>

      <section class="features-section">
        <div class="auto-container">
          <div class="anim-icons">
            <span class="icon icon-shape-3 wow fadeIn"></span>
            <span class="icon icon-line-1 wow fadeIn"></span>
          </div>
          <div class="row">
            <div class="feature-block col-lg-4 col-md-6 col-sm-12 wow fadeInUp">
              <div class="inner-box">
                <div class="icon-box">
                  <span class="icon flaticon-success"></span>
                </div>
                <h4>
                  <a href="about.html">Connect & Collaborate</a>
                </h4>
                <div class="text">
                  The Convention is a premier networking platform for tourism
                  and hospitality, bringing together industry leaders to
                  exchange ideas, best practices, and innovative solutions,
                  driving growth and development.
                </div>
              </div>
            </div>
            <div
              class="feature-block col-lg-4 col-md-6 col-sm-12 wow fadeInUp"
              data-wow-delay="400ms"
            >
              <div class="inner-box">
                <div class="icon-box">
                  <span class="icon flaticon-diamond-1"></span>
                </div>
                <h4>
                  <a href="about.html">Insightful Sessions</a>
                </h4>
                <div class="text">
                  The Convention offers insightful business sessions, keynote
                  addresses, and presentations by industry experts, exploring
                  emerging trends, challenges, and opportunities in hospitality
                  and tourism, providing valuable insights and strategic
                  guidance.
                </div>
              </div>
            </div>
            <div
              class="feature-block col-lg-4 col-md-6 col-sm-12 wow fadeInUp"
              data-wow-delay="800ms"
            >
              <div class="inner-box">
                <div class="icon-box">
                  <span class="icon flaticon-search"></span>
                </div>
                <h4>
                  <a href="about.html">Knowledge Hub</a>
                </h4>
                <div class="text">
                  Engage in a dynamic environment at The Convention, featuring
                  business sessions, keynote speeches, and expert presentations
                  on the latest trends, challenges, and opportunities in
                  hospitality and tourism. Gain strategic insights and
                  actionable knowledge from industry leaders.
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        class="speakers-section"
        style={{ backgroundImage: "url(images/background/6.jpg)" }}
      >
        <div class="auto-container">
          <div class="sec-title light text-center">
            <h2>EMINENT DIGNITARIES</h2>
          </div>
          <div class="row">
            {speaker_Record &&
              speaker_Record.map((user, i) => (
                <div class="speaker-block col-lg-3 col-md-6 col-sm-12" key={i}>
                  <div class="inner-box">
                    <div class="image-box">
                      <figure class="image">
                        <a>
                          <img
                            src={`https://d1iakh2uzy2hor.cloudfront.net/speaker/${user.photo}`}
                            alt={user.name}
                          />
                        </a>
                      </figure>
                    </div>
                  </div>
                </div>
              ))}
            {/* <div class="speaker-block col-lg-3 col-md-6 col-sm-12">
              <div class="inner-box">
                <div class="image-box">
                  <figure class="image">
                    <a href="speakers-detail.html">
                      <img src="images/resource/speaker-2.jpg" alt="" />
                    </a>
                  </figure>
                </div>
              </div>
            </div>
            <div class="speaker-block col-lg-3 col-md-6 col-sm-12">
              <div class="inner-box">
                <div class="image-box">
                  <figure class="image">
                    <a href="speakers-detail.html">
                      <img src="images/resource/speaker-3.jpg" alt="" />
                    </a>
                  </figure>
                </div>
              </div>
            </div>
            <div class="speaker-block col-lg-3 col-md-6 col-sm-12">
              <div class="inner-box">
                <div class="image-box">
                  <figure class="image">
                    <a href="speakers-detail.html">
                      <img src="images/resource/speaker-4.jpg" alt="" />
                    </a>
                  </figure>
                </div>
              </div>
            </div>
            <div class="speaker-block col-lg-3 col-md-6 col-sm-12">
              <div class="inner-box">
                <div class="image-box">
                  <figure class="image">
                    <a href="speakers-detail.html">
                      <img src="images/resource/speaker-5.jpg" alt="" />
                    </a>
                  </figure>
                </div>
              </div>
            </div>
            <div class="speaker-block col-lg-3 col-md-6 col-sm-12">
              <div class="inner-box">
                <div class="image-box">
                  <figure class="image">
                    <a href="speakers-detail.html">
                      <img src="images/resource/speaker-6.jpg" alt="" />
                    </a>
                  </figure>
                </div>
              </div>
            </div>
            <div class="speaker-block col-lg-3 col-md-6 col-sm-12">
              <div class="inner-box">
                <div class="image-box">
                  <figure class="image">
                    <a href="speakers-detail.html">
                      <img src="images/resource/speaker-7.jpg" alt="" />
                    </a>
                  </figure>
                </div>
              </div>
            </div>
            <div class="speaker-block col-lg-3 col-md-6 col-sm-12">
              <div class="inner-box">
                <div class="image-box">
                  <figure class="image">
                    <a href="speakers-detail.html">
                      <img src="images/resource/speaker-8.jpg" alt="" />
                    </a>
                  </figure>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </section>
      <section class="features-section-two">
        <div class="auto-container">
          <div class="anim-icons">
            <span class="icon twist-line-1 wow zoomIn"></span>
            <span
              class="icon twist-line-2 wow zoomIn"
              data-wow-delay="1s"
            ></span>
            <span
              class="icon twist-line-3 wow zoomIn"
              data-wow-delay="2s"
            ></span>
          </div>

          <div class="row">
            {/* <!-- Title Block --> */}
            <div class="title-block col-lg-4 col-md-12 col-sm-12 wow fadeInUp">
              <div class="inner-box">
                <div class="sec-title">
                  {/* <!-- <span class="title">Features</span> --> */}
                  <h2>Event Highlights</h2>
                </div>
              </div>
            </div>

            {/* <!-- Feature Block --> */}
            <div class="feature-block-two col-lg-4 col-md-6 col-sm-12 wow fadeInUp">
              <div class="inner-box">
                <div class="icon-box">
                  <span class="icon flaticon-lecture"></span>
                </div>
                <h4>
                  <a href="about.html">Premier Business Platform</a>
                </h4>
                <div class="text">Engage with top industry professionals</div>
              </div>
            </div>

            {/* <!-- Feature Block --> */}
            <div class="feature-block-two col-lg-4 col-md-6 col-sm-12 wow fadeInUp">
              <div class="inner-box">
                <div class="icon-box">
                  <span class="icon flaticon-search"></span>
                </div>
                <h4>
                  <a href="about.html">B2B and B2G Meetings</a>
                </h4>
                <div class="text">
                  Explore business and government collaborations
                </div>
              </div>
            </div>

            {/* <!-- Feature Block --> */}
            <div
              class="feature-block-two col-lg-4 col-md-6 col-sm-12 wow fadeInUp"
              data-wow-delay="400ms"
            >
              <div class="inner-box">
                <div class="icon-box">
                  <span class="icon flaticon-diamond-1"></span>
                </div>
                <h4>
                  <a href="about.html">Cultural Performances</a>
                </h4>
                <div class="text">Experience captivating performances</div>
              </div>
            </div>

            {/* <!-- Feature Block --> */}
            <div
              class="feature-block-two col-lg-4 col-md-6 col-sm-12 wow fadeInUp"
              data-wow-delay="800ms"
            >
              <div class="inner-box">
                <div class="icon-box">
                  <span class="icon flaticon-success"></span>
                </div>
                <h4>
                  <a href="about.html">HRANI Excellence Awards</a>
                </h4>
                <div class="text">Honor industry achievements</div>
              </div>
            </div>

            {/* <!-- Feature Block --> */}
            <div
              class="feature-block-two col-lg-4 col-md-6 col-sm-12 wow fadeInUp"
              data-wow-delay="1200ms"
            >
              <div class="inner-box">
                <div class="icon-box">
                  <span class="icon flaticon-employee"></span>
                </div>
                <h4>
                  <a href="about.html">Eminent Speakers</a>
                </h4>
                <div class="text">Learn from thought leaders</div>
              </div>
            </div>

            {/* <!-- Feature Block --> */}
            <div
              class="feature-block-two col-lg-4 col-md-6 col-sm-12 wow fadeInUp"
              data-wow-delay="1200ms"
            >
              <div class="inner-box">
                <div class="icon-box">
                  <span class="icon flaticon-worldwide"></span>
                </div>
                <h4>
                  <a href="about.html">Networking Opportunities</a>
                </h4>
                <div class="text">Connect with peers and leaders</div>
              </div>
            </div>

            {/* <!-- Feature Block --> */}
            <div
              class="feature-block-two col-lg-4 col-md-6 col-sm-12 wow fadeInUp"
              data-wow-delay="1200ms"
            >
              <div class="inner-box">
                <div class="icon-box">
                  <span class="icon flaticon-menu"></span>
                </div>
                <h4>
                  <a href="about.html">Networking Lunch</a>
                </h4>
                <div class="text">Enjoy a sumptuous networking meal</div>
              </div>
            </div>
          </div>
        </div>
      </section>


      <section class="clients-section">
        <div class="anim-icons">
          <span class="icon icon-dots-3 wow zoomIn"></span>
          <span class="icon icon-circle-blue wow zoomIn"></span>
        </div>
        <div class="auto-container">
          <div class="sec-title text-center">
            {/* <!--  <span class="title">Clients</span>--> */}
            {/* <h2>Our Sponsors</h2> */}
          </div>

          <div class="sponsors-outer text-center">
            <h3>Supported by</h3>

            <div class="row">
             
              <div class="client-block col-lg-4 col-md-4 col-sm-12" >
                <figure class="image-box">
                  <a href="#">
                    <img
                    // style={{height:'150px'}}
                      src="https://d1iakh2uzy2hor.cloudfront.net/file-1721830752877.png"
                      alt=""
                    />
                  </a>
                </figure>
              </div>
              <div class="client-block col-lg-4 col-md-4 col-sm-12" >
               <figure class="image-box">
                 <a href="#">
                   <img
                  //  style={{height:'150px'}}
                     src="https://d1iakh2uzy2hor.cloudfront.net/file-1722086509470.jpeg"
                     alt=""
                   />
                 </a>
               </figure>
             </div>

             <div class="client-block col-lg-4 col-md-4 col-sm-12" >
               <figure class="image-box">
                 <a href="#">
                   <img
                   style={{height:'80px'}}
                     src="https://d1iakh2uzy2hor.cloudfront.net/file-1722519206806.png"
                     alt=""
                   />
                 </a>
               </figure>
             </div>
            </div>
         
          </div>

          <div class="sponsors-outer text-center">
            <h3>Co-Host </h3>

            <div class="row">
              {/* <!-- Client Block --> */}
              <div class="client-block col-lg-12 col-md-12 col-sm-12">
                <figure class="image-box">
                  <a href="#">
                    <img
                      src="https://d1iakh2uzy2hor.cloudfront.net/file-1721996332667.png"
                      alt=""
                    />
                  </a>
                </figure>
              </div>
            </div>
          </div>
         
          <div class="sponsors-outer text-center">
            <h3>Partner State</h3>
            <div class="row">
              {/* <!-- Client Block --> */}
              <div class="client-block col-lg-12 col-md-12 col-sm-12">
                <figure class="image-box">
                  <a href="#">
                    <img
                      // src="https://d1iakh2uzy2hor.cloudfront.net/file-1721823083233.png"
                      src="https://d1iakh2uzy2hor.cloudfront.net/file-1721996647136.png"
                      alt=""
                     className="important-height"
                    />
                  </a>
                </figure>
              </div>

              {/* <!-- Client Block --> */}
              {/* <div class="client-block col-lg-3 col-md-6 col-sm-12">
                        <figure class="image-box"><a href="#"><img src="https://d1iakh2uzy2hor.cloudfront.net/file-1721141667700.png" alt="" /></a></figure>
                    </div> */}
            </div>
          </div>
          <div class="sec-title text-center">
            {/* <!--  <span class="title">Clients</span>--> */}
            <h2>Sponsor</h2>
          </div>
          <div class="sponsors-outer text-center">
            <h3>Platinum Sponsors </h3>

            <div class="row">
              {/* <!-- Client Block --> */}
              <div class="client-block col-lg-12 col-md-12 col-sm-12">
                <figure class="image-box">
                  <a href="#">
                    <img
                      src="https://d1iakh2uzy2hor.cloudfront.net/file-1721823156664.jpeg"
                      alt=""
                    />
                  </a>
                </figure>
              </div>
            </div>
          </div>
         
          {/* <div class="sponsors-outer text-center">
            <h3>Silver Sponsors</h3>

            <div class="row">
             
              <div class="client-block col-lg-3 col-md-6 col-sm-12">
                <figure class="image-box">
                  <a href="#">
                    <img
                      src="https://d1iakh2uzy2hor.cloudfront.net/file-1721141765721.png"
                      alt=""
                    />
                  </a>
                </figure>
              </div>

             
              <div class="client-block col-lg-3 col-md-6 col-sm-12">
                <figure class="image-box">
                  <a href="#">
                    <img
                      src="https://d1iakh2uzy2hor.cloudfront.net/file-1721141780647.png"
                      alt=""
                    />
                  </a>
                </figure>
              </div>

             
              <div class="client-block col-lg-3 col-md-6 col-sm-12 text-center">
                <figure class="image-box">
                  <a href="#">
                    <img
                      src="https://d1iakh2uzy2hor.cloudfront.net/file-1721141795749.png"
                      alt=""
                    />
                  </a>
                </figure>
              </div>

             
              <div class="client-block col-lg-3 col-md-6 col-sm-12">
                <figure class="image-box">
                  <a href="#">
                    <img
                      src="https://d1iakh2uzy2hor.cloudfront.net/file-1721141813286.png"
                      alt=""
                    />
                  </a>
                </figure>
              </div>
            </div>
          </div> */}
          {/* <div class="sponsors-outer">
            <h3>State Partner</h3>

            <div class="row">
              <div class="client-block col-lg-3 col-md-6 col-sm-12">
                <figure class="image-box">
                  <a href="#">
                    <img
                      src="https://d1iakh2uzy2hor.cloudfront.net/file-1721141871153.png"
                      alt=""
                    />
                  </a>
                </figure>
              </div>
            </div>
          </div> */}
        </div>
      </section>


      {/* <!--End Features Section --> */}

      {/* <!-- Video Section --> */}
      {/* <!-- <section class="video-section" style="background-image: url(images/background/1.jpg);">
    <div class="auto-container">
        <div class="content-box">
            <div class="text">WE’RE A LEADING INDUSTRY COMPANY</div>
            <h2>We Are Always at The <br>Forefront of The Business Conference !</h2>
            <a href="https://www.youtube.com/watch?v=Fvae8nxzVz4" class="play-now" data-fancybox="gallery" data-caption=""><i class="icon flaticon-play-button-3" aria-hidden="true"></i><span class="ripple"></span></a>
        </div>
    </div>
</section> -->
<!--End Video Section -->

<!-- Why Choose Us --> */}
      {/* <!-- <section class="why-choose-us">
    <div class="auto-container">
        <div class="row">
            <div class="content-column col-lg-6 col-md-12 col-sm-12 order-2">
                <div class="inner-column">
                    <div class="sec-title">
                        <span class="title">JOIN THE EVENT</span>
                        <h2>Why Choose Eventrox?</h2>
                        <div class="text">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmtempor incididunt labore et dolore magna aliqu enim ad minim veniam quis nostrud exercitation ullamco laboris nisi ut aliquip</div>
                    </div>
                    <ul class="list-style-one">
                        <li>High Quality Education</li>
                        <li>You can learn anything</li>
                        <li>We list your options by state</li>
                        <li>Expert-created content and resources</li>
                    </ul>
                    <div class="btn-box">
                        <a href="buy-ticket.html" class="theme-btn btn-style-two"><span class="btn-title">Get Tickets</span></a>
                    </div>
                </div>
            </div>
            <div class="image-column col-lg-6 col-md-12 col-sm-12">
                <div class="image-box">
                    <figure class="image"><img src="images/background/3.jpg" alt=""></figure>
                </div>
            </div>
        </div>
    </div>
</section> -->
<!-- End Why Choose Us -->

<!--Clients Section--> */}
      {/* <!-- <section class="clients-section">
    <div class="anim-icons">
        <span class="icon icon-dots-3 wow zoomIn"></span>
        <span class="icon icon-circle-blue wow zoomIn"></span>
    </div>
    <div class="auto-container">
        <div class="sec-title">
            <span class="title">Clients</span>
            <h2>Offcial Sponsors</h2>
        </div>

        <div class="sponsors-outer">
            <h3>Platinum Sponsors</h3>
            <div class="row">
               
                <div class="client-block col-lg-3 col-md-6 col-sm-12">
                    <figure class="image-box"><a href="#"><img src="images/clients/1.png" alt=""></a></figure>
                </div>

                
                <div class="client-block col-lg-3 col-md-6 col-sm-12">
                    <figure class="image-box"><a href="#"><img src="images/clients/2.png" alt=""></a></figure>
                </div>

                
                <div class="client-block col-lg-3 col-md-6 col-sm-12">
                    <figure class="image-box"><a href="#"><img src="images/clients/3.png" alt=""></a></figure>
                </div>

              
                <div class="client-block col-lg-3 col-md-6 col-sm-12">
                    <figure class="image-box"><a href="#"><img src="images/clients/4.png" alt=""></a></figure>
                </div>
            </div>
        </div>

        <div class="sponsors-outer">
            <h3>Gold Sponsors</h3>

            <div class="row">
                
                <div class="client-block col-lg-3 col-md-6 col-sm-12">
                    <figure class="image-box"><a href="#"><img src="images/clients/5.png" alt=""></a></figure>
                </div>

               
                <div class="client-block col-lg-3 col-md-6 col-sm-12">
                    <figure class="image-box"><a href="#"><img src="images/clients/6.png" alt=""></a></figure>
                </div>

                
                <div class="client-block col-lg-3 col-md-6 col-sm-12">
                    <figure class="image-box"><a href="#"><img src="images/clients/7.png" alt=""></a></figure>
                </div>

               
                <div class="client-block col-lg-3 col-md-6 col-sm-12">
                    <figure class="image-box"><a href="#"><img src="images/clients/8.png" alt=""></a></figure>
                </div>
            </div>
        </div>
    </div>
</section> -->
<!--End Clients Section-->

<!-- Event Info Section --> */}
      <section class="event-info-section">
        <div class="auto-container">
          <div class="row">
            {/* <!-- Info Column --> */}
            <div class="info-column col-lg-6 col-md-12 col-sm-12">
              <div class="inner-column">
                <div class="sec-title style-two">
                  <span class="title">Reach us</span>
                  <h2>
                    Direction for the <br />
                    Event
                  </h2>
                </div>

                <div class="event-info-tabs tabs-box">
                  {/* <!--Tabs Box--> */}
                  <ul class="tab-buttons clearfix">
                    <li class="tab-btn active-btn" data-tab="#tab1">
                      Venue
                    </li>
                    {/* <!-- <li class="tab-btn" data-tab="#tab2">Venue</li> --> */}
                    <li class="tab-btn" data-tab="#tab2">
                      Contact
                    </li>
                  </ul>

                  <div class="tabs-content">
                    {/* <!--Tab--> */}
                    <div class="tab active-tab" id="tab1">
                      <h4>
                        <span></span> INDIA EXPO CENTRE & MART
                      </h4>
                      <div class="text">
                        Hall No. 14-15, Knowledge Park II, <br />
                        Greater Noida, <br />
                        Uttar Pradesh 201306
                      </div>
                      {/* <!-- <ul class="info-list">
                                    <li><span class="icon icon_profile"></span> Dianne Ameter</li>
                                    <li><span class="icon icon_phone"></span> <a href="tel:+690-147-265-345">+123 456 7890</a></li>
                                    <li><span class="icon icon_mail"></span> <a href="mailto:sopport@Eventrox.com">sopport@Eventrox.com</a></li>
                                </ul> --> */}
                    </div>

                    {/* <!--Tab--> */}
                    <div class="tab" id="tab2">
                      <h4>
                        <span class="icon icon_profile"></span>Renu Thapliyal
                      </h4>
                      <ul class="info-list">
                        <li> Secretary General, HRANI</li>
                        <li>
                          <span class="icon icon_phone"></span>{" "}
                          <a href="tel:011-41306628ABOUT HRANI CONVENTION">011-41306628,011-42804416</a>
                        </li>
                        <li>
                          <span class="icon icon_mail"></span>{" "}
                          <a href="mailto:hrani.1950@gmail.com">
                            hrani.1950@gmail.com
                          </a>
                        </li>
                      </ul>
                    </div>

                    {/* <!--Tab--> */}
                    {/* <!-- <div class="tab" id="tab3">
                                <h4><span class="icon fa fa-directions"></span> How to get there</h4>
                                <div class="text">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt labore et dolore magna aliqua. Aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore.</div>
                            </div> --> */}
                  </div>
                </div>
              </div>
            </div>

            {/* <!-- Image Column --> */}
            <div class="image-column col-lg-6 col-md-12 col-sm-12">
              <figure class="image">
                <img src="images/map-4.png" alt="" />
              </figure>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Home;
