import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const API_URL = "https://api.expoplan.in/api/readRecord";
const stage_url = 'https://stageapi.expoplan.in/api/readRecord'
const pay_url = "https://smartgatewayuat.hdfcbank.com/session";

// Fetch CMS Pages
export const fetchCmsPages = createAsyncThunk("cms/fetchCmsPages", async () => {
  const response = await fetch(API_URL, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      table: "cms_pages",
      selectFields: {},
      whereCondition: {
        exb_id: "668d12e57d72eb70ec482c69",
        parent_id: "668e54fb9e788b0772c99d33",
        deleted: 'null'
      },
      sortBy: { position: 1 },
      limit: "0",
      populate: [],
      page: "1",
    }),
  });

  if (!response.ok) {
    throw new Error("Failed to fetch CMS pages");
  }

  const data = await response.json();
  return data;
});

let cmsPageCache = {};

// Fetch CMS Pages by Slug
export const fetchCmsPages3 = createAsyncThunk(
  'cms/fetchCmsPages3',
  async (slug, { rejectWithValue }) => {
    let slug_url = localStorage.getItem('slug_url');

    try {
      const response = await fetch(API_URL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          table: 'cms_pages',
          selectFields: {},
          whereCondition: { exb_id: '668d12e57d72eb70ec482c69', slug_url: slug || slug_url, deleted: 'null' },
          sortBy: { position: 1 },
          limit: '0',
          populate: [],
          page: '1',
        }),
      });

      if (!response.ok) {
        throw new Error('Failed to fetch CMS pages');
      }

      const data = await response.json();
      if (data.status) {
        const cmsDataList = data.data;
        const cmsData = cmsDataList[0];

        const title = cmsData.page_title || '3rd HRANI Convention';
        const description = cmsData.seo_des || 'Default description';
        const keywords = cmsData.seo_keyword || 'Default keywords';

        const pageData = {
          content: cmsData,
          title,
          description,
          keywords,
        };

        cmsPageCache[slug] = pageData;
        return pageData;
      }

      throw new Error('Failed to fetch CMS pages');
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

// Fetch CMS Pages 2
export const fetchCmsPages2 = createAsyncThunk(
  "cms/fetchCmsPages2",
  async () => {
    const response = await fetch(API_URL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        table: "cms_pages",
        selectFields: {},
        whereCondition: { exb_id: "668d12e57d72eb70ec482c69", deleted: 'null' },
        sortBy: { position: 1 },
        limit: "0",
        populate: [],
        page: "1",
      }),
    });

    if (!response.ok) {
      throw new Error("Failed to fetch CMS pages 2");
    }

    const data = await response.json();
    return data;
  }
);

// HDFC Payment API
export const hdfcpaymentapi = createAsyncThunk(
  "cms/hdfcpaymentapi",
  async (body) => {
    let username = '294D8FDB9934A0D8D040E64D44C18A'; 
    let password = ''; 
    const basicAuth = btoa(`${username}:${password}`);
    const headers = {
      'x-merchantid': 'SG537',
      'x-customerid': 'temp123',
      'Content-Type': 'application/json',
      'Authorization': `Basic ${basicAuth}`
    };

    const response = await fetch(pay_url, {
      method: "POST",
      headers: headers,
      body: JSON.stringify(body),
    });

    if (!response.ok) {
      throw new Error("Failed to fetch HDFC payment API");
    }

    const data = await response.json();
    return data;
  }
);

// Fetch Speaker Data
export const fetchSpeakerData = createAsyncThunk(
  "cms/fetchSpeakerData",
  async () => {
    const response = await fetch(API_URL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        table: "speakermanagement",
        selectFields: {},
        whereCondition: {exb_id: "668d12e57d72eb70ec482c69", deleted: 'null' },
        sortBy: {position:1},
        limit: "0",
        populate: [],
        page: "1",
      }),
    });

    if (!response.ok) {
      throw new Error("Failed to fetch speaker data");
    }

    const data = await response.json();
    return data;
  }
);


export const fetchVisitordata = createAsyncThunk(
  "cms/fetchVisitordata",
  async (barcodeValue, { rejectWithValue }) => {
    try {
      const response = await fetch(API_URL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          table: "form_visitors",
          selectFields: {},
          whereCondition: {exb_id: "668d12e57d72eb70ec482c69", 'form_data.barcode': barcodeValue, deleted: "null" },
          sortBy: {},
          limit: '0',
          populate: [],
          page: '1',
        }),
      });

      if (!response.ok) {
        throw new Error("Failed to fetch visitor data");
      }

      const data = await response.json();
      if (data.status && data.data.length > 0) {
        return data.data;
      } else {
        return rejectWithValue("Record not found");
      }
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const cmsSlice = createSlice({
  name: "cms",
  initialState: {
    pages: [],
    pages2: [],
    speaker_Record: [],
    paymentApi: [],
    isVisitor_Record: [],
    status: "idle",
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCmsPages3.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchCmsPages3.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.page = action.payload;
      })
      .addCase(fetchCmsPages3.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(fetchCmsPages.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchCmsPages.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.pages = action.payload;
      })
      .addCase(fetchCmsPages.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(fetchCmsPages2.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchCmsPages2.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.pages2 = action.payload;
      })
      .addCase(fetchCmsPages2.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(hdfcpaymentapi.pending, (state) => {
        state.status = "loading";
      })
      .addCase(hdfcpaymentapi.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.paymentApi = action.payload;
      })
      .addCase(hdfcpaymentapi.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(fetchSpeakerData.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchSpeakerData.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.speaker_Record = action.payload;
      })
      .addCase(fetchSpeakerData.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(fetchVisitordata.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchVisitordata.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.isVisitor_Record = action.payload;
      })
      .addCase(fetchVisitordata.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export default cmsSlice.reducer;
