import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import "./header.css";
import { IoClose, IoMenu, IoChevronDown } from "react-icons/io5"; // Import necessary icons
import "./header.css";
import {
  fetchCmsPages,
  fetchCmsPages2,
  fetchCmsPages3,
} from "../../Redux/Slice"; // Adjust the path as necessary

function Header() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const cmsPages = useSelector((state) => state.cms.pages.data);
  const [slugurl, setSlugurl] = useState("");
  useEffect(() => {
    let data = localStorage.getItem("slug");
    // setSlugurl(location.pathname);
   
      dispatch(fetchCmsPages());
      dispatch(fetchCmsPages2());
      dispatch(fetchCmsPages3(data));
    
  }, [location, dispatch]);

  const handleLinkClick = (url) => {
    const isExternalLink = /^https?:\/\//.test(url);
  
    if (isExternalLink) {
      window.open(url, "_self");
    } else {
      localStorage.setItem("slug_url", url);
      navigate(`/${url}`);
    }
  };

  return (
    <div className="page-wrapper">
      {/* <Header/> */}
     
      <header className="main-header" style={{background:'#101130'}}>
        <div className="main-box" >
          <div className="auto-container clearfix">
            <div className="logo-box">
              <div className="logo" style={{ marginTop: "-6px" }}>
                <Link to="/home">
                  <img src="images/logo.png" width="60%" alt="" title="" />
                </Link>
              </div>
            </div>

            {/* <!--Nav Box--> */}
            <div className="main-menu nav-outer clearfix">
            <div className="mobile-nav-toggler" >
                  <span className="icon flaticon-menu"></span>
                </div>
              {/* <!-- Main Menu --> */}
              <nav className=" navbar-expand-md navbar-light">
                <div className="navbar-header">
                  {/* <!-- Togg le Button --> */}
                  <button
                    className="navbar-toggler"
                    type="button"
                    data-toggle="collapse"
                    data-target="#navbarSupportedContent"
                    aria-controls="navbarSupportedContent"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                  >
                    <span className="icon flaticon-menu-button"></span>
                  </button>
                </div>

                <div
                  className="collapse navbar-collapse clearfix"
                  id="navbarSupportedContent"
                >
                  <ul className="navigation clearfix">
                    {cmsPages &&
                      cmsPages.map((menu, i) => (
                        <li className=" dropdown" key={i}>
                          <a  onClick={() => handleLinkClick(menu.slug_url)}>{menu.page_name}</a>
                        </li>
                      ))}

                    {/* <li className="current dropdown">
                      <a href="index.html">Home</a>{" "}
                    </li> */}

                    {/* <li className="dropdown">
                      <a href="about-us.html">Registration</a>
                     
                    </li> */}
                    {/* <li className="dropdown">
                      <a href="speakers.html">Hospitality Awards</a>
                    
                    </li>
                    <li className="dropdown">
                      <a href="schedule.html">Sponsorship</a>
                    </li> */}
                  </ul>
                  <div className="outer-box">
              
              <div className="btn-box">
                <a href="https://admin.expoplan.in/static-visitor?org_id=668d11d87d72eb70ec482b18&exb_id=668d12e57d72eb70ec482c69&form_id=668e7fecfa3da7855d1f36b9&form=web" className="theme-btn btn-style-one">
                  <span className="btn-title">Register Now!</span>
                </a>
              </div>
            </div>
                </div>
                <div className="outer-box">
              
            
            </div>
              </nav>
              {/* <!-- Main Menu End--> */}

              {/* <!-- Outer box --> */}
              {/* <div className="outer-box">
              
                <div className="btn-box">
                  <a href="buy-ticket.html" className="theme-btn btn-style-one">
                    <span className="btn-title">Register Now!</span>
                  </a>
                </div>
              </div> */}
            </div>
          </div>
        </div>

        {/* <!-- Mobile Menu  --> */}
        <div className="mobile-menu">
          <div className="menu-backdrop"></div>
          <div className="close-btn">
            <span className="icon flaticon-cancel-1"></span>
          </div>

          {/* <!--Here Menu Will Come Automatically Via Javascript / Same Menu as in Header--> */}
          <nav className="menu-box">
            <div className="nav-logo">
              <a href="index.html">
                <img src="images/logo-2.png" alt="" title="" />
              </a>
            </div>

            <ul className="navigation clearfix"></ul>
          </nav>
        </div>
      </header>
  

    </div>
  );
}

export default Header;
