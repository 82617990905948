import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
// import 'bootstrap/dist/css/bootstrap.min.css';
// import './css/style.css';
// import './css/responsive.css';
// import './css/color-switcher-design.css';

const Contact = () => {
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    // Check if the page has already been reloaded
    window.scrollTo(0, 0);
    const reloaded = sessionStorage.getItem("reloaded");

    if (!reloaded) {
      // Set a flag in sessionStorage to indicate the page has been reloaded
      sessionStorage.setItem("reloaded", "true");
      // Reload the page
      window.location.reload();
    } else {
      // If the page has already been reloaded, set the loading state to true
      setIsLoaded(true);
    }
  }, []);

  useEffect(() => {
    if (isLoaded) {
      // Hide the preloader once the content is fully loaded
      const preloader = document.querySelector(".preloader");
      if (preloader) {
        preloader.style.display = "none";
      }
    }
  }, [isLoaded]);
  const logos = [
    "https://www.fhraiconvention.com/cdn/images/clients/2-2.png",
    "https://www.fhraiconvention.com/cdn/images/clients/2-3.png",
    "https://www.fhraiconvention.com/cdn/images/clients/2-5.png",
    "https://www.fhraiconvention.com/cdn/images/clients/2-6.png",
  ];
  const footerLogosStyle = {
    display: "flex",
    // justifyContent: 'space-around',
    alignItems: "center",
    padding: "20px 0",
    backgroundColor: "#f9f9f9",
  };

  const footerLogoStyle = {
    flex: 1,
    textAlign: "center",
  };

  const imgStyle = {
    maxWidth: "100%",
    height: "auto",
    display: "inline-block",
    margin: "0 10px",
  };
  return (
    <div className="page-wrapper">
      {/* Preloader */}
      <div className="preloader">
        <div className="loader"></div>
      </div>
      {/* Header Span */}
      <span className="header-span"></span>

      <section
        className="page-title"
        style={{
          backgroundImage:
            "url(https://www.fhraiconvention.com/cdn/images/background/02.jpg)",
        }}
      >
        <div className="auto-container">
          <h1>Contact Us</h1>
          <ul className="bread-crumb clearfix">
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>Contact Us</li>
          </ul>
        </div>
      </section>
      {/* End Page Title */}

      {/* Contact Page Section */}
      <section className="contact-page-section">
        <div className="auto-container">
          <div className="row clearfix">
            <div className="contact-column col-lg-4 col-md-12 col-sm-12 order-2">
              <div className="inner-column">
                <div className="sec-title">
                  <h2>Contact Info</h2>
                </div>
                <ul className="contact-info">
                  <li>
                    <span className="icon fa fa-map-marker-alt"></span>
                    <p>
                      <strong>B-82, 8th Floor, Himalaya House,</strong>
                    </p>
                    <p>23 K.G. Marg, New Delhi - 110 001</p>
                  </li>

                  <li>
                    <span className="icon fa fa-phone-volume"></span>
                    <p>
                      <strong>Call Us</strong>
                    </p>
                    <p>011-40780780</p>
                  </li>

                  <li>
                    <span className="icon fa fa-envelope"></span>
                    <p>
                      <strong>Mail Us</strong>
                    </p>
                    <p>
                      <a href="mailto:fhrai@fhrai.com">fhrai@fhrai.com</a> |
                      convention54@fhrai.com
                    </p>
                  </li>

                  <li>
                    <span className="icon fa fa-clock"></span>
                    <p>
                      <strong>Opening Time</strong>
                    </p>
                    <p>Mon - Sat: 09.00am to 18.00pm</p>
                  </li>
                </ul>

                <ul className="social-icon-two social-icon-colored">
                  <li>
                    <a href="#">
                      <i className="fab fa-facebook"></i>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i className="fab fa-twitter"></i>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i className="fab fa-google-plus"></i>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i className="fab fa-dribbble"></i>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i className="fab fa-pinterest"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>

            {/* Form Column */}
            <div className="form-column col-lg-8 col-md-12 col-sm-12">
              <section className="map-section">
                <div className="auto-container">
                  <div className="map-outer">
                    <iframe
                      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3502.0548073323353!2d77.21699577358883!3d28.62811943052354!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390cfd343ee427f9%3A0x478e8eb1545a9a6b!2sHIMALAYA%20HOUSE%2C%20CONNAUGHT%20PLACE%2C%20Atul%20Grove%20Road%2C%20Janpath%2C%20Connaught%20Place%2C%20New%20Delhi%2C%20Delhi%20110001!5e0!3m2!1sen!2sin!4v1717132679689!5m2!1sen!2sin"
                      height="540"
                      style={{ border: 0 }}
                      allowFullScreen=""
                      // loading="lazy"
                      referrerPolicy="no-referrer-when-downgrade"
                      title="Google Map"
                    ></iframe>
                  </div>
                </div>
              </section>
              {/* <div className="inner-column">
                <div className="contact-form">
                  <div className="sec-title">
                    <h2>Get in Touch</h2>
                  </div>
                  <form method="post" action="sendemail.php" id="contact-form">
                    <div className="row clearfix">
                      <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                        <input type="text" name="username" placeholder="Name" required />
                      </div>

                      <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                        <input type="text" name="phone" placeholder="Phone" required />
                      </div>

                      <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                        <input type="email" name="email" placeholder="Email" required />
                      </div>

                      <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                        <input type="text" name="subject" placeholder="Subject" required />
                      </div>

                      <div className="col-lg-12 col-md-12 col-sm-12 form-group">
                        <textarea name="message" placeholder="Message"></textarea>
                      </div>

                      <div className="col-lg-12 col-md-12 col-sm-12 form-group">
                        <button className="theme-btn btn-style-one" type="submit" name="submit-form">
                          <span className="btn-title">Submit Now</span>
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </section>
      {/* End Contact Page Section */}

      {/* Map Section */}
      {/* <section className="map-section">
        <div className="auto-container">
          <div className="map-outer">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3502.0548073323353!2d77.21699577358883!3d28.62811943052354!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390cfd343ee427f9%3A0x478e8eb1545a9a6b!2sHIMALAYA%20HOUSE%2C%20CONNAUGHT%20PLACE%2C%20Atul%20Grove%20Road%2C%20Janpath%2C%20Connaught%20Place%2C%20New%20Delhi%2C%20Delhi%20110001!5e0!3m2!1sen!2sin!4v1717132679689!5m2!1sen!2sin"
              height="540"
              style={{ border: 0 }}
              allowFullScreen=""
              // loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
              title="Google Map"
            ></iframe>
          </div>
        </div>
      </section> */}
      {/* End Map Section */}
    </div>
  );
};

export default Contact;
