import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import Home from './Pages/Home/Home';
import AboutConvention from './Pages/About/ABT-Convention/AbtConventin';
import AboutConventioncity from './Pages/About/ABT-Convention/BoutConventioncity';
import Message from './Pages/About/ABT-Convention/Message';
import Download from './Pages/About/ABT-Convention/Download';
import Fhrexecutive from './Pages/About/ABT-Convention/Fhriexecutivecommitee';
import Venue from './Pages/About/ABT-Convention/venue';
import ComingSoon from './Pages/Comingsoon/Comingsoon';
import Awards from './Pages/Awards/Awards';
import Registerationonline from './Pages/About/Registration/Registrationonline';
import Registerationoffline from './Pages/About/Registration/Registrationoffline';
import Registerationfee from './Pages/About/Registration/Registrationfee';
import Contact from './Pages/Contact/Contact';
import Exhibition from './Pages/Exhibition/Exhibition';
import PostConvention from './Pages/About/Programme/PostConvention';
import Programme from './Pages/About/Programme/Programme';
import SpouseProgramme from './Pages/About/Programme/SpouseProgramme';
import Speakers from './Pages/Speaker/Speakrs';
import Sponsorship from './Pages/Sponsorship/Sponsorship';
import About from './Pages/About/About';

import CmsPages from './Pages/CmsPages/Cmspages';
import Header from './Layout/Header/Header';
import Footer from './Layout/Footer/Footer';
import HdfcPayForm from './Pages/paymentForm/HdfcPayForm';
import '@fortawesome/fontawesome-free/css/all.min.css';


import ReactGA from 'react-ga';

ReactGA.initialize('G-KK8Q4G0FFL');

function App() {
  // Track page view on initial load
  ReactGA.pageview(window.location.pathname + window.location.search);

  return (
    <div className="App">
      <Router>
     
          <Header/>
          <Routes>
          <Route path="" element={<Home />} />
          <Route path="/" element={<Home />} />
          <Route path="/home" element={<Home />} />
          <Route path="/:slug" element={<CmsPages />} /> 
          <Route path="/contact" element={<Contact />} />
          <Route path='/payment' element={<HdfcPayForm />} />
          <Route path="/awards-of-excellence" element={<Awards />} />
          {/* <Route path="/register" element={<Registerationonline />} /> */}
          {/* <Route path="/aboutconvention" element={<AboutConvention />} />
          <Route path="/aboutconventioncity" element={<AboutConventioncity />} />
          <Route path="/venue" element={<Venue />} />
          <Route path="/fhrexecutive" element={<Fhrexecutive />} />
          <Route path="/comingsoon" element={<ComingSoon />} />
          <Route path="/awards-of-excellence" element={<Awards />} />
          <Route path="/postconvention" element={<PostConvention />} />
          <Route path="/programme" element={<Programme />} />
          <Route path="/spouseprogramme" element={<SpouseProgramme />} />
          <Route path="/register" element={<Registerationfee />} />
          <Route path="/registeronline" element={<Registerationonline />} />
          <Route path="/registeroffline" element={<Registerationoffline />} />
          <Route path="/download" element={<Download />} />
          <Route path="/message" element={<Message />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/exhibition" element={<Exhibition />} />
          <Route path="/speakers" element={<Speakers />} />
          <Route path="/sponsorship" element={<Sponsorship />} />
          
          <Route path="/about" element={<About />} />
          {/* Add more routes for other pages as needed */}
          </Routes>
          <Footer/>
        
      </Router>
    </div>
  );
}

export default App;
