import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import "./footer.css";
import '@fortawesome/fontawesome-free/css/all.min.css';
import {
  fetchCmsPages,
  fetchCmsPages2,
  fetchCmsPages3,
} from "../../Redux/Slice";
import { VERSION } from "../config";

function Footer() {

  return (
    <div className="page-wrapper">
       <footer class="main-footer">
  
        <div class="footer-bottom" >
          <div class="auto-container">
            <div class="inner-container clearfix">
              <div class="copyright-text">
                <p>
                  © Copyright 2024 All Rights Reserved by{" "}
                  <a href="#">ExpoPlan.in - an Event Management Platform</a> <br />
                  <span className="text-danger mt-3s">{VERSION}</span>
                </p>
                
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default Footer;
